import { graphql, useStaticQuery } from "gatsby";
import React from "react";

const defVals = {
  locale: "en",
  t: {},
  settings: {},
};

// does not include the default one
const supportedLocale = new Set(["it"]);

// reducer
const KVArray2obj = (resultingObject, arrayElement) => {
  const { key, value } = arrayElement;
  if (key && value) {
    resultingObject[key] = value;
  }
  return resultingObject;
};

export const LanguageContext = React.createContext(defVals);

export const LanguageProvider = ({ children, location }) => {
  const possibleLocale = location.pathname.split("/")[1];
  const locale =
    possibleLocale && supportedLocale.has(possibleLocale)
      ? possibleLocale
      : "en";

  const data = useStaticQuery(graphql`
    query translations {
      t: allStoryblokEntry(
        filter: { field_component: { eq: "Translations" } }
      ) {
        nodes {
          content
          lang
        }
      }
      s: allStoryblokEntry(
        filter: { field_component: { eq: "Site Settings" } }
      ) {
        nodes {
          content
          lang
        }
      }
    }
  `);

  const translations = data.t.nodes.reduce((obj, node) => {
    obj[node.lang === "default" ? "en" : node.lang] = (
      JSON.parse(node?.content || {})?.elements ?? []
    ).reduce(KVArray2obj, {});
    return obj;
  }, {});

  const settings = data.s.nodes.reduce((obj, node) => {
    obj[node.lang === "default" ? "en" : node.lang] = JSON.parse(
      node?.content || {}
    );
    return obj;
  }, {});

  return (
    <LanguageContext.Provider
      value={{
        locale,
        t: translations[locale] ?? {},
        settings: settings[locale] ?? {},
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
