import React from "react";
import { LanguageProvider } from "./src/components/context/language-context";
import "./src/global.css";

export const wrapPageElement = ({ element, props }) => (
  <LanguageProvider {...props}>{element}</LanguageProvider>
);

export const onRouteUpdate = (apiCallbackContext) => {
  if (
    apiCallbackContext.prevLocation &&
    window?._iub?.cs?.api?.isConsentGiven &&
    window._iub.cs.api.isConsentGiven() &&
    window?.dataLayer
  ) {
    window.dataLayer.push({ event: "iub_consent_given" });
  }
};
